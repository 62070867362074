@mixin mob($breakpoint) {
  @if $breakpoint==320 {
    @media (max-width: 320px) {
      @content;
    }
  }

  @if $breakpoint==375 {
    @media (max-width: 375px) {
      @content;
    }
  }

  @if $breakpoint==480 {
    @media (max-width: 480px) {
      @content;
    }
  }

  @if $breakpoint==500 {
    @media (max-width: 500px) {
      @content;
    }
  }

  @if $breakpoint==600 {
    @media (max-width: 600px) {
      @content;
    }
  }

  @if $breakpoint==650 {
    @media (max-width: 650px) {
      @content;
    }
  }

  @if $breakpoint==768 {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==900 {
    @media (max-width: 900px) {
      @content;
    }
  }

  @if $breakpoint==1024 {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint==1100 {
    @media (max-width: 1100px) {
      @content;
    }
  }

  @if $breakpoint==1140 {
    @media (max-width: 1140px) {
      @content;
    }
  }

  @if $breakpoint==1220 {
    @media (max-width: 1220px) {
      @content;
    }
  }

  @if $breakpoint==1280 {
    @media (max-width: 1280px) {
      @content;
    }
  }

  @if $breakpoint==1300 {
    @media (max-width: 1300px) {
      @content;
    }
  }

  @if $breakpoint==1390 {
    @media (max-width: 1390px) {
      @content;
    }
  }

  @if $breakpoint==1440 {
    @media (max-width: 1440px) {
      @content;
    }
  }

  @if $breakpoint==1500 {
    @media (max-width: 1500px) {
      @content;
    }
  }

  @if $breakpoint==1800 {
    @media (max-width: 1800px) {
      @content;
    }
  }
}

@mixin button {
  padding: 10px;
  outline: none;
  border-radius: 25px;
  border: 0;
  cursor: pointer;
  margin: 20px 0 0;
  text-align: center;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'acumin-pro', sans-serif;
  font-weight: 700;
}
