.tnm-v1 * {
  font-family: ibm-plex-serif, sans-serif;
  font-weight: 300;
  font-size: 21px;
}

/** Many of these styles conflict with the styles
  * I've done in the ordering system
  * we need to find a way to scope them to just the static pages of the site
  * so for now I'm excluding them
  */

.tnm-v1 h1 {
  font-size: 72px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  line-height: 76px;

  @include mob(1300) {
    font-size: 60px;
    line-height: 64px;
  }

  @include mob(1100) {
    font-size: 40px;
    line-height: 44px;
  }

  @include mob(900) {
    font-size: 36px;
    line-height: 40px;
  }
}

.tnm-v1 h2 {
  font-size: 45px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  line-height: 49px;

  @include mob(900) {
    font-size: 30px;
    line-height: 34px;
  }

  span {
    display: block;
  }
}

.tnm-v1 h3 {
  font-size: 17px;
  line-height: 21px;
  font-family: acumin-pro-semi-condensed, sans-serif;
}

.tnm-v1 p {
  font-size: 21px;
  line-height: 30px;
  margin: 11px 0;
}

.tnm-v1 .dish-nutrition-and-allergy-info {
  display: none;
}

.tnm-v1 .cn-set-cookie.cn-button.bootstrap {
  width: 200px;
  margin: 10px 20px;
  border-radius: 25px;
  font-family: acumin-pro-semi-condensed, sans-serif;
  font-weight: 700;
  display: inline-block;
  background: #177f7a;
  color: #cafbe2;
}

.tnm-v1 .nav-up {
  top: -89px;
}
