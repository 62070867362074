.woocommerce-checkout {
  .page-hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 204px;
    background-color: #177f7a;
    color: #fff;
    border-bottom: 1px solid #0d0d0d;
  }

  .woocommerce {
    margin: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .woocommerce-form-coupon-toggle {
    width: 100%;
  }
}
