section {
  &.get-in-touch {
    padding: 100px 0;

    @include mob(900) {
      padding: 100px 30px;
      box-sizing: border-box;
    }

    h2 {
      margin-bottom: 40px;
    }

    .contact-blocks {
      display: flex;
      flex-direction: row;

      @include mob(900) {
        flex-direction: column;
      }
    }

    .contacts {
      display: inline-flex;
      flex-direction: column;
      width: 33.33%;
      padding: 30px 0;

      @include mob(900) {
        width: 100%;
      }

      &:nth-child(2) {
        border-left: 2px dashed $black;
        border-right: 2px dashed $black;
        padding: 30px 60px;
        box-sizing: border-box;

        @include mob(900) {
          border-left: 0;
          border-right: 0;
          border-top: 2px dashed $black;
          border-bottom: 2px dashed $black;
          padding: 30px 0;
        }
      }

      &:nth-child(3) {
        padding: 30px 60px;
        box-sizing: border-box;

        @include mob(900) {
          padding: 30px 0;
        }
      }
    }

    p {
      font-family: 'acumin-pro-semi-condensed', sans-serif;
      font-weight: 600;
    }

    h3 {
      text-decoration: underline;
      margin-bottom: 30px;
    }

    a {
      text-decoration: none;
      color: $black;
      font-weight: 600;
    }
  }
}
