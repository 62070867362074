section {
  &.how-it-works {
    padding: 100px 0;
    background: #fff2d2;
    box-sizing: border-box;
    border-top: 1px solid #0d0d0d;

    @include mob(900) {
      padding: 50px 0;
    }

    .how-it-works-block {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @include mob(900) {
        flex-wrap: wrap;
      }
    }

    .hiw-block {
      width: 25%;
      padding: 10px;
      box-sizing: border-box;
      text-align: center;
      display: flex;
      flex-direction: column;
      position: relative;

      @include mob(900) {
        width: 50%;
      }

      @include mob(600) {
        width: 100%;
        padding: 20px 0;
      }

      &:nth-child(even) {
        padding-top: 180px;

        @include mob(900) {
          padding-top: 10px;
        }
      }
    }

    h2 {
      text-align: center;
      font-size: 45px;
      margin-bottom: 40px;

      @include mob(900) {
        font-size: 45px;
        margin-bottom: 49px;
      }
    }

    h3 {
      text-align: left;
      font-size: 30px;

      @include mob(900) {
        font-size: 24px;
      }
    }

    .image-block {
      max-width: 330px;
      width: 100%;
      height: 250px;
      display: block;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 0 auto 20px;
      background-position: 50%;
    }

    .text-block {
      text-align: left;
    }
  }
}
