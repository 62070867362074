@import "../styles/main.scss";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  h1, h2, h3, h4 {
    font-weight: 700;
  }
}

html {
  box-sizing: border-box;
}

.tnm-v1 {
  font-size: 21px;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  line-height: 1;
  font-family: 'ibm-plex-serif', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: hsla(0, 0%, 0%, 0.8);
  // word-wrap: break-word;
  // font-kerning: normal;
  // -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  // -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  // -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  // font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}

* {
  box-sizing: inherit;
}

li {
  margin-bottom: 10px;
}

*::before {
  box-sizing: inherit;
}

*::after {
  box-sizing: inherit;
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}
