section {
  &.why-choose-us {
    padding: 100px 0;
    border-top: 1px solid #0d0d0d;
    border-bottom: 1px solid #0d0d0d;
    position: relative;
    box-sizing: border-box;

    @include mob(900) {
      padding: 50px 0;
    }

    .text-block {
      max-width: 630px;
      width: 100%;
      display: block;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 50px;
    }

    h2 {
      margin-bottom: 20px;
    }

    .reasons-block {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    h3 {
      font-family: 'ibm-plex-mono', sans-serif;
      margin-top: 10px;
      text-align: center;
    }

    .info-box {
      display: none;
    }

    .image-block {
      display: block;
      max-width: 180px;
      height: 180px;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 0 auto;
      background-position: center bottom;

      @include mob(900) {
        height: 130px;
      }
    }

    .reasons {
      width: 25%;
      display: block;
      padding: 20px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;

      @include mob(900) {
        width: 50%;
        padding: 10px 10px 0;
      }

      &:hover {
        .info-box {
          display: block;
          position: absolute;
          border: 1px solid #0d0d0d;
          border-radius: 25px;
          padding: 30px;
          background-color: #fff;
          z-index: 10;
          left: 0;
          right: 0;
          margin-top: 20px;

          @include mob(900) {
            display: none;
          }
        }
      }
    }

    .info-toggle {
      img {
        display: none;

        @include mob(900) {
          display: block;
          width: 20px;
          height: 20px;
          margin: 0 auto;
          transform: rotate(-90deg);
          margin-top: 20px;
          cursor: pointer;
        }
      }

      &.active {
        .info-box {
          display: block;
          position: absolute;
          border: 1px solid #0d0d0d;
          border-radius: 25px;
          padding: 30px;
          background-color: #fff;
          left: 0;
          right: 0;
          margin-top: 20px;
          z-index: 10;

          @include mob(600) {
            font-size: 16px;
            line-height: 20px;
          }

          &.left {
            @include mob(600) {
              left: 0%;
              right: -100%;
            }
          }

          &.right {
            @include mob(600) {
              left: -100%;
              right: 0;
            }
          }

          &.center {
            @include mob(600) {
              left: -50%;
              right: -50%;
            }
          }
        }
      }

      &.spin {
        img {
          transform: rotate(90deg);
        }
      }
    }

    .wcu-btn {
      @include button;

      background: #292929;
      max-width: 200px;
      width: 100%;
      color: #fff;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 100px);
      right: calc(50% - 100px);
      border: 1px solid $black;

      @include mob(900) {
        left: 0;
        right: 0;
        margin: 0 auto;
      }

      &:hover {
        background: $white !important;
        color: $black !important;
      }
    }
  }
}
