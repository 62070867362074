@import 'common/variables';

/** Import theme styles */
@import 'common/reset';
@import 'common/mixins';

@import 'common/global';

@import 'components/banner-breaks';
@import 'components/book-consultation';
@import 'components/dishes';
@import 'components/3-reasons-why';
@import 'components/how-it-works';
@import 'components/video';
@import 'components/testimonials';
@import 'components/get-in-touch';
@import 'components/reasons';
@import 'components/sample-menus';
@import 'components/easteregg';
@import 'components/meal-plans-order';
@import 'components/meals';
@import 'components/buttons';
@import 'components/comments';
@import 'components/forms';
@import 'components/wp-classes';
@import 'components/socials';
@import 'layouts/header';
@import 'layouts/sidebar';
// @import "layouts/footer";
@import 'layouts/pages';
@import 'layouts/posts';
@import 'layouts/products';
@import 'layouts/pricing';
@import 'layouts/order';
@import 'layouts/home';
@import 'layouts/our-story';
@import 'layouts/the-plans';
@import 'layouts/why-choose-us';
@import 'layouts/get-started';
@import 'layouts/faq';
@import 'layouts/legal';
@import 'layouts/basket';
@import 'layouts/checkout';
@import 'layouts/tinymce';

@tailwind base;
@tailwind components;
@tailwind utilities;
