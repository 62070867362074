.woocommerce-cart {
  .page-hero {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 204px;
    background-color: #cafbe2;
    color: #141414;
    border-bottom: 1px solid #0d0d0d;
  }

  .woocommerce {
    margin: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cart-empty.woocommerce-info {
    width: 100%;
  }

  .woocommerce-cart-form {
    width: 100%;
  }
}
